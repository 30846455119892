import React, { useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import SlideMenu from "./SlideMenu";
import { MEDIA_QUERY_MD, MEDIA_QUERY_LG } from "../constants/styles";
import { useOnClickOutside } from "./App/hooks";

export default function Header({ handleChange, open, setOpen }) {
  const location = useLocation();
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  return (
    <HeaderContainer>
        <div ref={node}>
          <StackNav open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </StackNav>
          <SlideMenu open={open} setOpen={setOpen} />
        </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 32px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.priBackgroundColor};

`;

const Brand = styled.div`
  color: ${({ theme }) => theme.logoBackgroundColor};
  font-size: 28px;
  font-weight: bold;
  align-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  background-color #21D4FD;
  background-image linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
  border-radius 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow 15px 15px 50px rgba(0,0,0,0.2);
  animation: morphing 6s infinite;
  overflow hidden;
  &:hover {
    animation-play-state paused;
  }
  @keyframes morphing {
    0% {
      border-radius 30% 70% 70% 30% / 30% 30% 70% 70%
      box-shadow 15px 15px 50px rgba(0,0,0,0.2)
    }
    25% {
      border-radius 58% 42% 75% 25% / 76% 46% 54% 24%
    }
    50% {
      border-radius 50% 50% 33% 67% / 55% 27% 73% 45%
      box-shadow -10px -5px 50px rgba(0,0,0,0.2)
    }
    75% {
      border-radius 33% 67% 58% 42% / 63% 68% 32% 37%
    }
  }
`;

const StackNav = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  visibility: hidden;

  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 0.12rem;
    background: ${({ open }) => (open ? "#ddd" : "#000")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) =>
        open ? "translateX(-20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  ${MEDIA_QUERY_LG} {
    visibility: visible;
  }
`;

