import React from "react";
import styled from "styled-components";

export default function HomePage({currentTheme}) {
    return (
        <>
            <Cover>
                
                <img
                    src={require("../resource/avatar.jpg")}
                    width="100%"
                    height={"100%"}
                    alt={"cover"}
                    style={{ position: "relative", left: "58px", backgroundColor: 'black' }}
                />
                <div
                    style={{
                        position: "absolute",
                        bottom: 20,
                        left: "-59px",
                    }}
                >
                    <h1 style={{ fontSize: "78px", letterSpacing: "-3px" }}>
                        PORTFOLIO
                    </h1>
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: 30,
                        left: "-59px",
                    }}
                >
                    <p style={{ fontSize: "24px", fontWeight: "500" }}>
                        BY SZUYINGLIN aka ABBIE
                    </p>
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: 20,
                        left: "-59px",
                        display: 'flex',
                    }}
                >
                    <Icon href="https://www.linkedin.com/in/szu-ying-lin-97b2bb144/" target="_blank">
                        <img
                            src={require("../resource/linkedin.png")}
                            alt="Linkedin"
                            width={24}
                            height={24}
                        />
                    </Icon>
                    <Icon href="https://github.com/b43b13" target="_blank">
                        <img
                            src={require("../resource/github-logo.png")}
                            alt="Github"
                            width={24}
                            height={24}
                        />
                    </Icon>
                    <Icon href="https://www.facebook.com/christinelin0102/" target="_blank">
                        <img
                            src={require("../resource/facebook.png")}
                            alt="Facebook"
                            width={24}
                            height={24}
                        />
                    </Icon>
                </div>
            </Cover>
        </>
    );
}


const Icon = styled.a`
    display: block;
    width: 30px;
    height: 30px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const Cover = styled.div`
    width: 693px;
    height: 489px;
    margin: auto;
    textAlign: right;
    position: relative;
`;
