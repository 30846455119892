import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const [windowDimensions, setWindowDimensions] = useState({width, height});

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  useEffect(() => {

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
