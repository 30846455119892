import React from "react";
import styled from "styled-components";
import { Board } from "../HomePage";
import { MEDIA_QUERY_MD } from "../../constants/styles";

export default function ProjectsPage() {
    return (
        <Board>
            <ProjectContainer>
                <ContentContainer>
                    <div>
                        <Title>Game Platform APP</Title>
                        <ProjectImg>
                            <img
                                src={
                                    require("../../resource/platform.png")
                                }
                                width="100%"
                                height="100%"
                                alt="gu-chat"
                            />
                        </ProjectImg>
                        <ul>
                            <li>
                                React-Native cross-platform (IOS, ANDROID) app
                                development from scratch.
                            </li>
                            <li>
                                Game platform of member system with withdrawal,
                                deposit, and plenty of third party games
                                integration, game records...etc.
                            </li>
                            <li>
                                Sentry integration for monitoring error issues
                                and crash report.
                            </li>
                            <li>
                                Built another same platform app with a different
                                brand fast with gulp..
                            </li>
                            <li>
                                GeeTest, Notification, Hotfix JS bundle, WebView
                                JS bridge, Lottery, Deeplink..etc.
                            </li>
                            <li>
                                CICD pipeline setup for building JS bundle, IPA
                                and APK.
                            </li>
                        </ul>
                    </div>
                </ContentContainer>

                <ContentContainer>
                    <div>
                        <Title>Gu-Chat APP</Title>
                        <ProjectImg>
                            <img
                                src={
                                    require("../../resource/guchat.png")
                                }
                                width="100%"
                                height="100%"
                                alt="gu-chat"
                            />
                        </ProjectImg>
                        <ul>
                            <li>
                            IOS chatting app built with react-native 0.67 & recoil.
                            </li>
                            <li>Websocket heartbeat integration.</li>
                            <li>Launching app to the IOS Appstore.</li>
                            <li>Performance Optimization with preventing re-render including using memo,
useMemo, useCallback... etc.</li>
                            <li>Overwrite and customize the third library with postinstall..</li>
                        </ul>
                    </div>
                </ContentContainer>

                <ContentContainer>
                    <div>
                        <Title>Game platform WEB</Title>
                        <ProjectImg>
                            <img
                                src={
                                    require("../../resource/platform_web.png")
                                }
                                width="100%"
                                height="100%"
                                alt="gu-chat"
                            />
                        </ProjectImg>
                        <ul>
                            <li>
                                Use P-React framework, improve the bundle size,
                                and enhance web loading time about 30% compared
                                with React.
                            </li>
                            <li>Webpack environment setup from scratch.</li>
                            <li>
                                CSSModules,Sass,PostCSS,RWDwithoutframework.
                            </li>
                            <li>
                                Restful API design and integration data with back-end engineer.
                            </li>
                            <li>Use Node.js and express to build API.</li>
                            <li>Lazy-loading,and making files to chunks for dynamic loadability.</li>
                        </ul>
                    </div>
                </ContentContainer>

                <ContentContainer>
                    <div>
                        <Title>CMS management system WEB</Title>
                        <ProjectImg>
                            <img
                                src={
                                    require("../../resource/admin.png")
                                }
                                width="100%"
                                height="100%"
                                alt="gu-chat"
                            />
                        </ProjectImg>
                        <ProjectImg>
                            <img
                                src={
                                    require("../../resource/chart_2.png")
                                }
                                width="100%"
                                height="100%"
                                alt="gu-chat"
                            />
                            <label># its mock datas</label>
                        </ProjectImg>
                        <ul>
                            <li>ManagementsystemofthemainCRUDfeature.</li>
                            <li>
                                Built a dashboard with visualization of datas by
                                multiple charts.
                            </li>
                            <li>Bootstrap, Material UI library.</li>
                        </ul>
                    </div>
                </ContentContainer>
            </ProjectContainer>
        </Board>
    );
}

const ProjectContainer = styled.div`
    max-width: 800px;
    background-color: ${({ theme }) => theme.priBackgroundColor};
    margin: 0px auto;
    ${MEDIA_QUERY_MD} {
    }
`;

const ContentContainer = styled.div`
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    ${MEDIA_QUERY_MD} {
        display: block;
        // max-width: 400px;
    }

    p {
        margin: 5px 0;
    }

    ul {
        padding-left: 20px;
    }
    li {
        margin-bottom: 5px;
    }
`;

const Title = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.fontColor};

    a {
        margin-bottom: 50px;
        color: ${({ theme }) => theme.fontColor};
    }
`;

const ProjectImg = styled.div`
    max-width: 600px;
    img {

        border: 1px solid #ddd;
    }
    margin-bottom: 20px;
`;

export { ProjectContainer, ContentContainer };
