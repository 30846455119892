import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import Theme from "./Theme/Theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import HomePage from "./pages/HomePage";
import GuChat from "./pages/projects/GuChat";
import ContactPage from "./pages/ContactPage";

function App() {
    const [currentTheme, setCurrentTheme] = useState("light");
    const [open, setOpen] = useState(false);
    const handleChange = () => {
        if (currentTheme === "dark") {
            setCurrentTheme("light");
        } else {
            setCurrentTheme("dark");
        }
    };
    return (
        <ThemeProvider theme={Theme[currentTheme]}>
            <Router>
                <div style={{ display: "flex",  padding: "1rem"}}>
                        <Menu />
                        <Switch>
                            <Route exact path="/">
                                <HomePage currentTheme={currentTheme} />
                            </Route>
                            <Route path="/projects">
                                <GuChat />
                            </Route>

                            <Route path="/contact">
                                <ContactPage />
                            </Route>

                        </Switch>
                </div>
                <Navbar
                    handleChange={handleChange}
                    open={open}
                    setOpen={setOpen}
                />

            </Router>
        </ThemeProvider>
    );
}

export default App;
