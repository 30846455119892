import React, { useRef } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { MEDIA_QUERY_MD, MEDIA_QUERY_LG } from "../constants/styles";
import { useOnClickOutside } from "./App/hooks";

export default function Header({ handleChange, open, setOpen }) {
    const location = useLocation();
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));
    return (
        <HeaderContainer>
            <NavbarList>
            {
                <div
                    style={{
                        fontFamily: "Georgia",
                        fontSize: "40px",
                        fontWeight: "bold",
                        letterSpacing: "-8px",
                        margin: 0,
                        marginTop: "16px",
                        marginBottom: "32px",
                        width: 100,
                        textAlign: 'right',
                    }}
                >
                    √A
                </div>
            }
                <Nav to="/" $active={location.pathname === "/"}>
                    About
                </Nav>
                {/* <Nav to={location.pathname}>
                    <p>/</p>
                </Nav> */}
                <Nav to="/projects" $active={location.pathname === "/projects"}>
                    Projects
                </Nav>
                {/* <Nav to="/game-platform-app" $active={location.pathname === "/game-platform-app"}>
                    GamePlatform
                </Nav>

                <Nav to="/design" $active={location.pathname === "/design"}>
                    Design
                </Nav> */}

                {/* <Nav to={location.pathname}>
                    <p>/</p>
                </Nav> */}
                <Nav to="/contact" $active={location.pathname === "/contact"}>
                    Contact
                </Nav>
                {/* <ToggleSwitch handleChange={handleChange} /> */}
            </NavbarList>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    font-family: sans-serif;
    position: fixed;
    text-align: right;
    margin-top: 8%;
    background: ${({ theme }) => theme.priBackgroundColor};

    ${MEDIA_QUERY_LG} {
        display: none;
    }
    @media only screen and (min-width: 1080px) {
        left: -1%;
    }
    @media only screen and (min-width: 1200px) {
        left: 5%;
    }
    @media only screen and (min-width: 1360px) {
        left: 8%;
    }
    @media only screen and (min-width: 1441px) {
        left: 15%;
    }

`;

const NavbarList = styled.div`
    background: ${({ theme }) => theme.priBackgroundColor};
    display: flex;
    flex-direction: column;
    ${MEDIA_QUERY_MD} {
        visibility: hidden;
    }
`;

const Nav = styled(Link)`
    text-align: right;
    font-size: 14px;
    line-height: 16px;
    color: #bcbcbc;
    text-decoration: none;
    //   &:before,
    //   :after {
    //     transition: all 0.5s;
    //   }
    //   &:after {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //     width: 0%;
    //     content: "";
    //     color: transparent;
    //     background: ${({ theme }) => theme.fontColor};
    //     height: 1px;
    //   }
    //   &:hover:after {
    //     width: 100%;
    //   }
    p {
        margin-bottom: 0;
    }

    ${(props) =>
        props.$active &&
        `
        color: #000;
    `}
`;

const MenuButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    visibility: hidden;

    &:focus {
        outline: none;
    }

    div {
        width: 1.5rem;
        height: 2px;
        background: ${({ theme }) => theme.logoBackgroundColor};
        background: #000;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        :first-child {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? "0" : "1")};
            transform: ${({ open }) =>
                open ? "translateX(-20px)" : "translateX(0)"};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
    ${MEDIA_QUERY_MD} {
        visibility: visible;
    }
`;
