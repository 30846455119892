const theme = {
	light: {
		logoBackgroundColor: "#1b1b1b",
		priBackgroundColor: "#ffffff",
		secBackgroundColor: "#ffffff",
		introBackgroundColor: "pink",
		fontColor: "#1b1b1b",
	},
	dark: {
		logoBackgroundColor: "#ffffff",
		priBackgroundColor: "#1b1b1b",
		secBackgroundColor: "#1b1b1b",
		introBackgroundColor: "#3a2166",
		fontColor: "#ffffff",
	},
};

export default theme;
