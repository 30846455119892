import React from "react";
import styled from "styled-components";
import { MEDIA_QUERY_LG, MEDIA_QUERY_MD } from "../../constants/styles";
import Cover from "../../components/Cover";
import Cover2 from "../../components/Cover2";
import useWindowDimensions from "../../hooks/useDimension";

export default function HomePage() {
    const { width } = useWindowDimensions();

    return (
        <Board>
            {width >= 768 ? <Cover /> : <Cover2 />}
            <AboutContainer>
                <h3>About</h3>
                <Para>
                    Hi, I'm Abbie! A front-end developer in 4 - 5 years
                    experience. Expert on React.js and React-Native. Not only
                    require myself to be pixel-perfected for UI, I also care
                    about the performance and easy-maintainable features when I
                    structure a project from scratch or maintained the old
                    project. I am a self-motivated and responsible person with a
                    strong work ethic, maybe a little bit workaholic. My
                    strengths are learning fast and being enthusiastic about
                    developing new skills and finding innovative solutions. It
                    lets me keep in progress in the software journey. I love
                    coding, love taking challenges. I believe KEEP LEARNING is
                    the only path to success. Also, I am a coffee addict, feel
                    free to contact me for knowing more about me. :)
                </Para>
            </AboutContainer>
            <Container>
                <div>
                    <>
                        <h2>Experience</h2>
                        <DetailContent>
                            <h3>Paradise-Soft (202003 - 202203)</h3>
                            <h4>Front-end Developer</h4>
                            <ul>
                                <li>
                                    Develop the main structure of cross-platform
                                    business production apps using react-native
                                    from scratch in both APK and IPA.
                                </li>
                                <li>
                                    Code refactoring from OOP class-based to FP
                                    react hook and optimizing performance.
                                </li>
                                <li>
                                    Maintain old and large architecture react
                                    native app, upgraded once from version 0.59
                                    -> 0.63 for some new features and unfixed
                                    bugs.
                                </li>
                                <li>
                                    Experience in launching applications in
                                    Apple Store and how to avoid the strict
                                    audit from the Apple Store by replacing
                                    resource package JS bundle.
                                </li>
                                <li>
                                    Monitor app crash and error tracking by
                                    using app-center, sentry.
                                </li>
                                <li>
                                    Response main structure, data flow,
                                    git-flow, coding style, release flow.
                                </li>
                                <li>
                                    Experienced in integrating Gitlab runner,
                                    .yml file, docker image environment setup
                                    for the CICD pipeline to release code more
                                    efficiently.
                                </li>
                                <li>
                                    Experienced in native modules integration
                                    with the native mobile developer.
                                </li>
                                <li>
                                    On call for debugging production issues and
                                    locating problems in apps across services
                                    such as security, CDN cache,
                                    code-push...etc.
                                </li>
                            </ul>
                        </DetailContent>
                        <DetailContent>
                            <h3>Xuenn (201911 - 202003)</h3>
                            <h4>IT consultant</h4>
                            <ul>
                                <li>
                                    Research Flutter & React Native
                                    cross-platform App and develop a Social App
                                    from scratch one of each.
                                </li>
                                <li>
                                    Do Design thinking research, market analysis
                                    on UX & UI, making own design guidelines.
                                </li>
                                <li>
                                    Front-end framework research and comparison.
                                </li>
                                <li>
                                    Chrome extension and Firefox extension
                                    development.
                                </li>
                            </ul>
                        </DetailContent>

                        <DetailContent>
                            <h3>Central Limit Company (201810 - 201909)</h3>
                            <h4>Front-end engineer</h4>
                            <ul>
                                <li>
                                    Built an admin management and platform entry
                                    website with P-react, Sass, Webpack,
                                    Material-design, i18n from scratch.
                                </li>
                                <li>
                                    Integrated API and defined the API interface
                                    with backend engineers together.
                                </li>
                                <li>
                                    Integrated WebSocket with heartbeat
                                    mechanism.
                                </li>
                                <li>
                                    Assist product manager in planning project
                                    processes and proposals for technical
                                    solutions.
                                </li>
                                <li>
                                    Feasibility analysis and implementation of
                                    architecture and new development methods.
                                </li>
                            </ul>
                        </DetailContent>

                        <DetailContent>
                            <h3>Creat Trust Company (201710 - 201809)</h3>
                            <h4>Web developer</h4>
                            <ul>
                                <li>
                                    Built an admin management system with pure
                                    JavaScript, HTML, and CSS, without framework
                                    and jQuery.
                                </li>
                                <li>
                                    UI & UX design and pixel perfect to
                                    HTML/CSS.
                                </li>
                                <li>Animation making without the library.</li>
                                <li>
                                    Fix issue for Browser Compatible, IE,
                                    Firefox.
                                </li>
                            </ul>
                        </DetailContent>

                        <DetailContent>
                            <h3>The index (201611 - 201705)</h3>
                            <h4>Web designer</h4>
                            <ul>
                                <li>
                                    Proposal and planning ideas in internet
                                    advertising marketing.
                                </li>
                                <li>
                                    UI & UX design and pixel perfect to
                                    HTML/CSS.
                                </li>
                                <li>Fan club management</li>
                                <li>
                                    Making campaign websites for business
                                    companies such as Audi, Abbott.
                                </li>
                            </ul>
                        </DetailContent>
                    </>
                </div>

                    <>
                        <h2>Skills</h2>
                <SkillSection>
                        <SkillContainer>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    JavaScript
                                </h4>
                                <YellowStar>★★★★</YellowStar>★
                            </div>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    NodeJS
                                </h4>
                                <YellowStar>★★★★</YellowStar>★
                            </div>
                        </SkillContainer>
                        <SkillContainer>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>React</h4>
                                <YellowStar>★★★★</YellowStar>★
                            </div>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    React-Native
                                </h4>
                                <YellowStar>★★★★</YellowStar>★
                            </div>
                        </SkillContainer>
                        <SkillContainer>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    CSS/SASS/SCSS
                                </h4>
                                <YellowStar>★★★★</YellowStar>★
                            </div>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>HTML</h4>
                                <YellowStar>★★★</YellowStar>★★
                            </div>
                        </SkillContainer>
                        <SkillContainer>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    express
                                </h4>
                                <YellowStar>★★</YellowStar>★★★
                            </div>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    Gitlab runner
                                </h4>
                                <YellowStar>★★★</YellowStar>★★
                            </div>
                        </SkillContainer>
                        <SkillContainer>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>Gulp</h4>
                                <YellowStar>★★★</YellowStar>★★
                            </div>
                            <div>
                                <h4 style={{ padding: 0, margin: 0 }}>
                                    Webpack
                                </h4>
                                <YellowStar>★★★</YellowStar>★★
                            </div>
                        </SkillContainer>
                </SkillSection>
                    </>

                <div>
                    <h2 style={{ marginTop: 50 }}>EDUCATION</h2>
                    <p>
                    Chang Gung University BA. Media-Visual Communication
                        Department (2011 - 2015)
                    </p>
                    <p>National Taichung Girls’High School (2011 - 2015)</p>
                </div>

                <div>
                    <h2 style={{ marginTop: 50 }}>AWARDS</h2>
                    <p>2014 Social Change Award-Gold</p>
                    <p>2014 Samsung Campus APP Star Award</p>
                </div>
            </Container>
        </Board>
    );
}

const Board = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.priBackgroundColor};
    color: ${({ theme }) => theme.fontColor};
    padding-top: 8%;
`;

const IntroContainer = styled.div`
    display: flex;
    max-width: 800px;
    background-color: ${({ theme }) => theme.introBackgroundColor};
    justify-content: space-around;
    align-items: center;
    margin: 0px auto;
    ${MEDIA_QUERY_MD} {
        display: block;
        align-items: center;
    }
`;

const Container = styled.div`
    max-width: 800px;
    margin: auto;
    h2 {
        margin-top: 50px;
    }

    ${MEDIA_QUERY_MD} {
        // display: block;
        // max-width: 400px;
        // padding: 10px;
    }
`;

const SkillContainer = styled.div`
    display: flex:
    marginBottom: 10px;
    justify-content: space-between;

    div {
        margin-bottom: 10px;
    }
`;

const SkillSection = styled.div`
    display: flex;
    justify-content: space-around;

    ${MEDIA_QUERY_MD} {
        // max-width: 400px;
        display: block;
        text-align: center;

    }
`;


const AboutContainer = styled.div`
    max-width: 800px;
    padding-bottom: 10px;
    background-color: ${({ theme }) => theme.priBackgroundColor};
    align-items: center;
    margin: 50px auto;
    padding: 10px;

    ${MEDIA_QUERY_MD} {
        // max-width: 400px;
    }
`;

const Para = styled.p`
    font-size: 1.1em;
    line-height: 1.5em;
    margin-bottom: 0;
`;

const DetailContent = styled.div`
    // max-width: 400px;
    margin-bottom: 20px;
    h3 {
        margin-bottom: 0px;
        margin-top: 0;
    }
    h4 {
        margin-bottom: 5px;
        margin-top: 0;
        font-weight: 600;
    }
    ul {
        margin: 0;
        padding-left: 20px;
    }
    li {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 300;
    }
    ${MEDIA_QUERY_MD} {
    }
`;

const YellowStar = styled.span`
    color: orange;
`;

export { Board, AboutContainer, IntroContainer };
