import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Board } from "../HomePage";
import { ProjectContainer } from "../projects/GuChat";

import { MEDIA_QUERY_MD } from "../../constants/styles";

export default function ContactPage() {
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleTextAreaChange = (e) => {
        console.log('e.target.value:', e.target.value)
        setMessage(e.target.value)

    }
    const handleOnSubmit = (e) => {
        document.getElementById("create-course-form").reset();
    }
    return (
        <Board>
            <ProjectContainer>
                <h2>Contact</h2>
                Looking forward to hearing from you!
                <Form
                    action="https://getform.io/f/fc95b944-5461-418b-aa41-eca0f5d0ca12"
                    method="POST"
                    id="create-course-form"
                    onSubmit={handleOnSubmit}
                >
                    Name
                    <Input type="text" name="name" onChange={handleNameChange} value={name} required/>
                    Email
                    <Input type="email" name="email" onChange={handleEmailChange} value={email} required />
                    Message
                    <Textarea name="message" rows="10" cols="50" onChange={handleTextAreaChange}required  value={message} />
                    <Submit
                        type="submit"

                    >
                        Submit
                    </Submit>
                </Form>
            </ProjectContainer>
        </Board>
    );
}

const Form = styled.form`
    margin-top: 50px;
`;

const Input = styled.input`
    width: 20%;
    height: 24px;
    margin-bottom: 20px;
    display: block;

    ${MEDIA_QUERY_MD} {
        width: 98%;
    }
`;

const Submit = styled.button`
    background-color: #000;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    ${MEDIA_QUERY_MD} {
        width: 100%;
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    display: block;
    resize: none;
    ${MEDIA_QUERY_MD} {
        width: 98%;
    }
`;
