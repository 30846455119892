import React from "react";
import styled from "styled-components";

export default function SlideMenu({ open }) {
    return (
        <StyledMenu open={open}>
            <a href="/">About</a>
            <a href="projects">Projects</a>
            <a href="/contact">Contact</a>
        </StyledMenu>
    );
}

const StyledMenu = styled.nav`
    position: absolute;
    flex-direction: column;
    background-color: #000;
    width: 100%;
    height: 100vh;
    text-align: left;
    padding-top: 50%;
    padding-left: 2rem;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    opacity: ${({ open }) => (open ? 1 : 0)};
    top: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out;
    a {
        font-size: 16px;
        text-align: right;
        margin-right: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 0px;
        color: #ddd;
        text-decoration: none;
    }
`;
